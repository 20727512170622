<template>
  <div class="row">
    <div class="card">
      <div class="card-body">
        <h5 class="table-heading">{{$t('reports.filter_reports')}}</h5>
        <message-card alertType="indicator-info" :alertTitle="$t('reports.filter_title')" :alertText="$t('reports.filter_text')" id="commentcard"></message-card>
        <Form @submit="(values) => generate(values)" :validation-schema="schema" ref="reportsForm">

          <!-- Vehicle -->
          <div class="row">
            <h6>Vehicle</h6>
            <hr/>

            <div class="col-md-3 col-xs-6 col-xxs-12 m-b-sm">
              <label for="vehicleRegistrationFilter" class="form-label">{{$t('events.filters.vehicle_registration')}}</label>
              <Field type="text" name="registration" class="form-control" id="vehicleRegistrationFilter" v-model="filters.registration" placeholder="Enter Registration"/>
              <ErrorMessage name="registration" class="error-feedback"/>
            </div>
          </div>

          <!-- Location -->
          <div class="row m-b-sm">
            <h6>Location</h6>
            <hr/>

            <div v-if="siteGroups.length > 0" class="col-md-3 col-xs-6 col-xxs-12 m-b-sm">
              <label for="siteGroupFilter" class="form-label">Site Group</label>
              <Multiselect
                v-model="filters.group"
                :class="{'multi-dark': $store.state.auth.status.dark}"
                mode="tags"
                placeholder="Select a site group"
                :close-on-select="false"
                :searchable="true"
                :create-option="false"
                :multiple="false"
                :options="siteGroups"
                label="name"
                track-by="id"
                name="siteGroup"
                @select="loadSites"
                @remove="loadSites()"
              />
              <ErrorMessage name="siteGroup" class="error-feedback"/>
            </div>

            <div class="col-md-3 col-xs-6 col-xxs-12 m-b-sm">
              <label for="siteFilter" class="form-label">{{ $t('events.filters.site') }} </label>
              <Multiselect
                v-model="filters.site"
                :class="{'multi-dark': $store.state.auth.status.dark}"
                mode="tags"
                placeholder="Select a site"
                :close-on-select="false"
                :searchable="true"
                :create-option="false"
                :multiple="true"
                :options="sites"
                label="name"
                track-by="id"
                name="site"
              />
              <ErrorMessage name="site" class="error-feedback"/>
            </div>
          </div>

          <!-- Claim Details -->
          <div class="row m-b-sm">
            <h6>Claim Details</h6>
            <hr/>

            <div class="col-md-3 col-xs-6 col-xxs-12 m-b-sm">
              <label for="typeFilter" class="form-label">{{ $t('reports.claim_type') }} </label>
              <Multiselect
                v-model="filters.type"
                :class="{'multi-dark': $store.state.auth.status.dark}"
                mode="tags"
                placeholder="Select a type"
                :close-on-select="false"
                :searchable="true"
                :create-option="true"
                :multiple="true"
                :options="claimTypes"
                label="label"
                track-by="value"
                name="type"
              />
              <ErrorMessage name="type" class="error-feedback" />
            </div>

            <div class="col-md-3 col-xs-6 col-xxs-12 m-b-sm">
              <label for="statusFilter" class="form-label">{{$t('claims.processing.status')}}</label>
              <Multiselect 
                v-model="filters.status"
                :class="{'multi-dark': $store.state.auth.status.dark}"
                :options="statuses"
                :multiple="true"
                :close-on-select="false"
                :clear-on-select="false"
                :preserve-search="true"
                name="status"
                id="statusFilter"
                placeholder="Select status"
              />
              <ErrorMessage name="status" class="error-feedback"/>
            </div>

            <div class="col-md-3 col-xs-6 col-xxs-12 m-b-sm">
              <label for="referenceFilter" class="form-label">{{ $t('sites.reference') }}</label>
              <Field type="text" name="reference" class="form-control" id="referenceFilter" placeholder="Enter reference" v-model="filters.reference"/>
              <ErrorMessage name="reference" class="error-feedback" />
            </div>

            <div v-if="filters.status.includes('Paid')" class="col-md-3 col-xs-6 col-xxs-12 m-b-sm">
              <div class="form-group">
                <label for="paidFilter" class="form-label">{{ $t('reports.sort_by') }}</label>
                <Field type="text" name="date_filter" class="form-select" id="paidFilter" as="select" v-model="sort_by" @change="sortDateField"> 
                  <option value="date_received" selected> {{$t('reports.date_received')}} </option>
                  <option value="date_paid"> {{$t('reports.date_paid')}} </option>
                </Field>
              </div>
            </div>

            <div class="row">
              <div class="col-md-3 col-xs-6 col-xxs-12 m-b-sm">
                <label for="valueSearch" class="form-label">Search by Value</label>
                <Field type="text" name="value_filter" class="form-select" id="value_filter" as="select" v-model="value_search"> 
                  <option :value="null" selected> - NO - </option>
                  <option value="exact"> Exact Value </option>
                  <option value="range"> Within Range </option>
                </Field>
              </div>
              <div v-show="value_search == 'range'" class="col-md-3 col-xs-6 col-xxs-12 m-b-sm">
                <label for="minValueFilter" class="form-label">Minimum Value</label>
                <Field type="number" min="0.00" step="0.01" name="min_value" class="form-control" id="minValueFilter" v-model="filters.min_value" placeholder="0.00" ref="min_value"/>
              </div>
              <div v-show="value_search == 'range'" class="col-md-3 col-xs-6 col-xxs-12 m-b-sm">
                <label for="maxValueFilter" class="form-label">Maximum Value</label>
                <Field type="number" min="0.00" step="0.01" name="max_value" class="form-control" id="maxValueFilter" v-model="filters.max_value" placeholder="0.00" ref="max_value"/>
              </div>
              <div v-show="value_search == 'exact'" class="col-md-3 col-xs-6 col-xxs-12 m-b-sm">
                <label for="valueFilter" class="form-label">Value</label>
                <Field type="number" min="0.00" step="0.01" name="value" class="form-control" id="valueFilter" v-model="filters.value" placeholder="0.00" ref="value"/>
              </div>
              <div class="col-md-3 col-xs-6 col-xxs-12 m-b-sm"></div>
            </div>

            <div class="row" v-show="filters.status.includes('Cancelled') && filters.status.length < 2">
              <p>You have selected to filter by Cancelled claims, would you like to filter for claims cancelled by a request from site?</p>
              <div class="col-md-3 col-xs-6 col-xxs-12 m-b-sm">
                <label for="cancelledBySiteFilter" class="form-label">Cancelled by Site</label>
                <Field type="text" name="requested_by_site" class="form-select" id="requested_by_site" as="select" v-model="requested_by_site"> 
                  <option :value="null" selected> NO </option>
                  <option :value="true"> YES </option>
                </Field>
              </div>
            </div>

          </div>
          
          <!-- Claim Details -->
          <div class="row m-b-sm">
            <h6>Time</h6>
            <hr/>

            <div class="col-md-3 col-xs-6 col-xxs-12 m-b-sm">
              <label for="startDateFilter" class="form-label">{{ $t('events.filters.start_date') }}</label>
              <Field type="date" name="start_date" class="form-control" id="startDateFilter" v-model="filters.start_date" ref="start_date" @change="addFocus('startDateFilter')"/>
              <ErrorMessage name="start_date" class="error-feedback" />
            </div>
            <div class="col-md-3 col-xs-6 col-xxs-12 m-b-sm">
                <label for="endDateFilter" class="form-label">{{ $t('events.filters.end_date') }}</label>
                <Field type="date" name="end_date" class="form-control" id="endDateFilter" v-model="filters.end_date" ref="end_date"/>
                <ErrorMessage name="end_date" class="error-feedback" />
            </div>

            <div class="row" v-if="this.filters.type.some(item => item.value === 'escalated')">
              <div class="col-md-3 col-xs-6 col-xxs-12 m-b-sm">
                <label for="escalatedStartDateFilter" class="form-label">{{ 'Escalated Start Date' }}</label>
                <Field type="date" name="escalated_start_date" class="form-control" id="escalatedStartDateFilter" v-model="filters.escalated_start_date" ref="escalated_start_date" @change="addFocus('escalatedStartDateFilter')"/>
                <ErrorMessage name="escalated_end_date" class="error-feedback" />
              </div>
              <div class="col-md-3 col-xs-6 col-xxs-12 m-b-sm">
                  <label for="escalatedEndDateFilter" class="form-label">{{ 'Escalated End Date' }}</label>
                  <Field type="date" name="escalated_end_date" class="form-control" id="escalatedEndDateFilter" v-model="filters.escalated_end_date" ref="escalated_end_date"/>
                  <ErrorMessage name="escalated_end_date" class="error-feedback" />
              </div>
            </div>

          </div>

          <!--Time -->
          <div class="row m-b-sm">
        
            <div class="col-12 m-b-sm">
              <div class="col-md-12 reports-container">
                <button class="btn btn-secondary m-r-sm" @click="updateWeek" > {{$t('reports.week')}} </button>
                <button class="btn btn-secondary m-h-sm" @click="updateMonth"> {{$t('reports.month')}} </button>
                <button class="btn btn-secondary m-h-sm" @click="updateQuarter"> {{$t('reports.quarter')}} </button>
                <button class="btn btn-secondary m-h-sm" @click="updateYear"> {{$t('reports.year')}} </button>
              </div>
            </div>

          </div>

          <div class="width-fit-content">
            <button type="submit" :disabled="loading" class="btn btn-primary m-r-sm" @click="showSecondButton = true; this.isShowingReports = true; this.isShowingTotals = false">
              <span v-show="loading" class="spinner-border spinner-border-sm m-r-xxs"></span>
              <span>{{ $t('reports.generate_report') }} </span>
            </button>

            <button class="btn btn-primary m-r-sm" @click="getSiteTotals()" v-show="showSecondButton">
              <span v-show="loading" class="spinner-border spinner-border-sm m-r-xxs"></span>
              <span>Show Totals</span>
            </button>

            <button type="reset" :disabled="loading" class="btn btn-secondary" @click.prevent="clearFilter">
              <span v-show="loading" class="spinner-border spinner-border-sm m-r-xxs"></span>
              <span>{{ $t('global.clear_filter') }}</span>
            </button>
          </div>
        </Form>
      </div>
    </div>
  </div>

  <!-- Loading -->
  <div v-show="loading" style="width: 100%; text-align: center;">
      <div class="spinner-border" style="height: 4rem; width: 4rem;" role="status">
          <span class="visually-hidden">{{ $t('events.loading') }}...</span>
      </div>
  </div>

  <div v-show="!loading && claims.length > 0">
  
    <!-- 1. Claims summary -->
    <div v-show="isShowingReports" class="row space-between">
      <div class="col-xl-3">
        <div class="card widget widget-info-navigation">
            <div class="card-body">
                <div class="widget-info-navigation-container">
                    <div class="widget-info-navigation-content">
                        <span class="text-muted">{{$t('reports.num_claims')}}</span><br>
                        <span class="text-primary fw-bolder fs-2">{{ filteredClaims.length }}</span>
                    </div>
                </div>
            </div>
        </div>
      </div>
      <div class="col-xl-3">
        <div class="card widget widget-info-navigation">
            <div class="card-body">
                <div class="widget-info-navigation-container">
                    <div class="widget-info-navigation-content">
                        <span class="text-muted">{{$t('reports.total_value')}}</span><br>
                        <span class="text-warning fw-bolder fs-2">£{{ totalValue }}</span>
                    </div>
                </div>
            </div>
        </div>
      </div>
      <div class="col-xl-3">
        <div class="card widget widget-info-navigation">
            <div class="card-body">
                <div class="widget-info-navigation-container">
                    <div class="widget-info-navigation-content">
                        <span class="text-muted">{{$t('reports.total_paid')}}</span><br>
                        <span class="text-success fw-bolder fs-2">£{{ totalPaid }}</span>
                    </div>
                </div>
            </div>
        </div>
      </div>
      <div class="col-xl-3">
        <div class="card widget widget-info-navigation">
            <div class="card-body">
                <div class="widget-info-navigation-container">
                    <div class="widget-info-navigation-content">
                        <span class="text-muted">{{$t('reports.total_cancelled')}}</span><br>
                        <span class="text-danger fw-bolder fs-2">£{{ totalCancelled }}</span>
                    </div>
                </div>
            </div>
        </div>
      </div>
    </div>

    <!-- 2. Charts -->
    <div v-show="isShowingReports" class="row">
      <div class="m-b-sm"> <button class="btn btn-secondary" style="float: right;" @click="toggleShowCharts"> {{showCharts === true ? 'Hide' : 'Show'}} {{$t('reports.charts')}} </button> </div>
    </div>
    <div v-show="isShowingReports" class="row m-b-md" v-if="showCharts">
      <div v-if="charts.claimType.show" class="col-xl-4">
        <div style="text-align: center;"> {{$t('reports.chart_overview', {text: 'Claim Type'})}} </div>
        <vue-apex-charts type="donut" @dataPointSelection="handleDataPointSelectionTypes" :options="charts.claimType.chartOptions" :series="charts.claimType.series"></vue-apex-charts>
      </div>
      <div v-if="charts.site.show" class="col-xl-4">
        <div style="text-align: center;"> {{$t('reports.chart_overview', {text: 'Site'})}} </div>
        <vue-apex-charts type="donut" @dataPointSelection="handleDataPointSelectionSites" :options="charts.site.chartOptions" :series="charts.site.series"></vue-apex-charts>
      </div>
      <div v-if="charts.status.show" class="col-xl-4">
        <div style="text-align: center;"> {{$t('reports.chart_overview', {text: 'Status'})}} </div>
        <vue-apex-charts type="donut" @dataPointSelection="handleDataPointSelection" :options="charts.status.chartOptions" :series="charts.status.series"></vue-apex-charts>
      </div>
    </div>

    <!-- 3. Export btns -->
    <div v-show="isShowingReports" class="flex m-b-sm" style="float: right;">
      <div class="col=2">
        <p style="line-height: 1.6; margin-bottom: 0px;">
          <i class="material-icons text-primary" title="Open claim in new tab" style="width: 23px; line-height: 1.6; vertical-align: middle;"> launch </i> <span> - Open claim in new tab</span>
        </p>
      </div>
      <div class="col=2" style="margin-left: 20px;">
        <p style="line-height: 1.6; margin-bottom: 0px;">
          <i class="material-icons text-danger" title="Request cancellation" style="width: 23px; line-height: 1.6; vertical-align: middle;"> cancel_icon </i> <span> - Request Cancellation</span>
        </p>
      </div>
      <div class="col=2" style="margin-left: 20px;">
        <button class="btn btn-secondary m-r-sm" @click="exportExcel"> Export Excel </button>
      </div>
      <div class="col=2">
        <button class="btn btn-secondary" @click="exportCSV"> Export CSV </button>
      </div>
    </div>

    <!-- 4. Reports Table -->
    <table v-show="isShowingReports" id="reporttable" class="table">
      <thead>
        <tr>
          <th>{{$t('sites.reference')}}</th>
          <th style="min-width: 140px;"> {{$t('staging.type')}} </th>
          <th colspan="2"> {{$t('claims.processing.status')}} </th>
          <th> {{$t('events.filters.site')}} </th>
          <th> {{$t('claims.new.value')}} </th>
          <th> {{$t('global.vehicle')}} </th>
          <th> {{dateFilter}} </th>
          <th v-if="this.filters.type.some(item => item.value === 'escalated')"> {{ 'Date Escalated' }} </th>
          <!-- <th> {{$t('reports.date_received')}} </th> -->
          <!-- <th v-if="filters.status.includes('Paid')"> {{$t('reports.date_paid')}} </th> -->
          <!-- <th></th> -->
          <th> {{$t('whitelist.action')}} </th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="claim in filteredClaims" :key="'claims-' + claim.id" style="vertical-align: middle;">
          <td>
            <a href="javascript:void(0);" @click="openClaim(claim)">{{claim.reference}}</a>
          </td>
          <td>
            {{ claim.formattedType }}
            <span v-if="claim.sub_type"> ({{ claim.sub_type }})</span>
            <span v-if="claim.type == 'cnafe'" class="text-warning"> (Customer Not at Fault)</span>
            <span v-if="claim.type == 'escalated'" class="text-warning"> (No Means of Payment)</span>
          </td>
          <td>
            {{claim.status}} <span v-if="claim.status === 'Debt Collection' && claim.debt_collection_stage">({{ claim.debt_collection_stage }})</span>
          </td>
          <td>
            <i class="material-icons" style="cursor: pointer;" @click="showStatus(claim)">help</i>
          </td>
          <td>{{claim.site.name}}</td>
          <td>£{{parseFloat(claim.value + (claim.value_second || 0)).toFixed(2)}}</td>
          <td>{{claim.registration}}</td>
          <td>
            <timestamp :time="claim.date_received" format="dd/MM/yyyy"></timestamp>
            <span class="text-success" v-if="claim.date_paid !== null && claim.date_paid !== undefined">Paid: <timestamp class="text-success" :time="claim.date_paid" format="dd/MM/yyyy"></timestamp></span>
          </td>
          <!-- <td v-if="claim.date_paid !== null && claim.date_paid !== undefined"> Paid: <timestamp :time="claim.date_paid" format="dd/MM/yyyy"></timestamp> </td> -->
          <td v-if="this.filters.type.some(item => item.value === 'escalated')">
            <timestamp :time="claim.date_escalated" format="dd/MM/yyyy"></timestamp>
          </td>
          <td>
            <!-- @click="openClaim(claim)" -->
            <!-- {{$t('reports.request_cancellation')}} -->
            <!-- <button class="btn btn-danger" @click="openCancellationModal(claim)"> <i class="material-icons" style="width: 20px;"> cancel_icon </i> <span class=""> </span> </button>
            <button class="btn btn-primary btn-style-light flex-grow-1" @click="openClaim(claim)"><i class="material-icons">open_in_new</i>{{ $t('events.view') }}</button> -->
            <div class="row" style="flex-wrap: nowrap;">
              <div v-show="(claim.status !== 'Paid' && claim.status !== 'Paid on Site' && claim.status !== 'Cancelled')" class="temp flex-center m-r-sm" style="background-color: rgba(220, 53, 69, .25)" @click="openModal(claim)">
                <i class="material-icons text-danger" title="Request cancellation" style="width: 23px;"> cancel_icon </i>
              </div>
              <div v-show="claim.status === 'Cancelled'" class="temp flex-center m-r-sm" style="background-color: rgba(111, 66, 193, .25)" @click="openCancelReasonModal(claim)" title="Cancellation Reason">
                <i class="material-icons text-purple" style="width: 23px;"> info </i>
              </div>
              <div class="temp flex-center" style="background-color: rgba(34, 105, 245, .25)">
                <i class="material-icons text-primary" title="Open claim in new tab" @click="openClaim(claim)"> launch </i>
              </div>
            </div>
          </td>
        </tr>
      </tbody>
    </table>

    <!-- 4.5. Totals Table -->

    <div v-show="isShowingTotals" >

      <div class="col-xl-3">
        <div class="card widget widget-info-navigation">
            <div class="card-body">
                <div class="widget-info-navigation-container">
                    <div class="widget-info-navigation-content">
                        <span class="text-muted">{{ "Total Collected on Sites" }}</span><br>
                        <span class="text-danger fw-bolder fs-2">£{{ this.totalValue }}</span>
                    </div>
                </div>
            </div>
        </div>
      </div>

      <table class="table">
        <thead>
          <tr>

          </tr>
        </thead>
        <tbody>
          <tr>
            
          </tr>
        </tbody>
      </table>

      <table id="totalsTable" class="table">
        <thead>
          <tr>
            <th> {{ "#" }} </th>
            <th> {{ "Status" }} </th>
            <th> {{ "Site" }} </th>
            <th> {{ "Value" }} </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(record, index) in siteTotalsList" :key="record.id">
            <td>{{ index + 1 }}</td>
            <td>{{ record.status }}</td>
            <td>{{ record.site }}</td>
            <td>£{{ record.totalValue }}</td>
          </tr>
        </tbody>
      </table>
    </div>

  </div>
  <div v-show="isClaimsEmpty" class="no-report-container m-t-md">
    <span> No Claims found that match your search results. </span>
  </div>

  <!-- Request cancellation modal -->
  <div class="modal fade" id="cancelClaimModal" tabindex="-1" aria-labelledby="cancelClaimTitle" style="display: none;" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content p-h-xxl p-v-xxl">
        <div class="modal-title">
          <h4 class="m-r-sm textAlign-center"> {{$t('reports.request_cancellation').toUpperCase()}} </h4>

          <div class="alert alert-custom alert-indicator-left indicator-warning" role="alert">
            <div class="alert-content">
                <span class="alert-title">Warning!</span>
                <span class="alert-text">Submitting a cancellation request does not guarantee that the case will be cancelled. Please do not inform a customer a case is closed until you have received confirmation from VARS.</span>
            </div>
          </div>

          <p class="modal-sub-title" id="reports"> Please select the reason you wish to cancel the case from the available options. If none of the options are relevant please explain why you wish to close the case using the input provided.</p>
          <select class="form-select m-b-md" v-model="cancelClaimData.selected_reason" placeholder="Select a reason">
            <option :value="null" selected> - </option>
            <!-- <option value="Paid on Site" v-if="cancelClaimData.claim?.type !== 'parking'"> Paid on Site </option> -->
            <option v-if="cancelClaimData.claim?.type !== 'parking'" value="Paid on Site">Paid on Site</option>
            <option v-if="cancelClaimData.claim?.type !== 'parking'" value="Duplicate Claim">Duplicate Claim</option>
            <option v-if="cancelClaimData.claim?.type === 'parking'" value="Client Request">Client Request</option>
            <option v-if="cancelClaimData.claim?.type === 'parking'" value="Contractor/Working on Site">Contractor/Working on Site</option>
            <option v-if="cancelClaimData.claim?.type === 'parking'" value="Genuine Customer">Genuine Customer</option>
            <option v-if="cancelClaimData.claim?.type === 'parking'" value="Hotel Guest">Hotel Guest</option>
            <option v-if="cancelClaimData.claim?.type === 'parking'" value="Special Circumstances">Special Circumstances</option>
            <option v-if="cancelClaimData.claim?.type === 'parking'" value="Staff">Staff</option>
            <option v-if="cancelClaimData.claim?.type === 'parking'" value="Visitor">Visitor</option>
          </select>
          <div class="form-group" style="width: 100%;">
            <textarea class="form-control" v-model="cancelClaimData.reason" placeholder="If you haven't selected a reason above, please explain why this case should be cancelled so that our team may review..." rows="5"></textarea>
          </div>
          <div class="checkbox-container">
            <input type="checkbox" v-model="cancelClaimData.send_cancellation_notice" id="sendCanellationNotice" class="form-checkbox"/> 
            <label for="sendCanellationNotice"> {{$t('reports.cancel_notice')}} </label>
          </div>
          <div class="flex-center p-v-sm">
            <button class="btn btn-secondary m-r-sm" @click="() => cancelClaimModal.hide()"> {{$t('global.close')}} </button>
            <button class="btn btn-primary" @click="processCancellation" :disabled="(cancelClaimData.reason == null || cancelClaimData.reason == '') && (cancelClaimData.selected_reason == null || cancelClaimData.selected_reason == '')"> {{$t('reports.submit_request')}} </button>
          </div>

        </div>
      </div>
    </div>
  </div>

  <div class="modal fade" id="cancelReasonModal" tabindex="-1" aria-labelledby="cancelReasonModal" style="display: none;" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content p-h-xxl p-v-xx">
        <div class="modal-body">
          <div class="modal-title textAlign-center">
            {{$t('reports.cancel_reason').toUpperCase()}}
          </div>
          <div>
            <span v-if="cancelledClaim.cancellation_reason !== null && cancelledClaim.cancellation_reason !== undefined"> {{cancelledClaim.cancellation_reason}}</span>
          </div>
          <div class="flex-center p-v-sm">
            <button class="btn btn-primary" @click="() => cancelReasonModal.hide()"> {{$t('global.close')}}</button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- Status Explanation Modal -->
  <div class="modal fade" id="statusExplanation" tabindex="-1" aria-labelledby="statusExplanationLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-sm">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">{{ showingStatus.status }}</h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
                <p><strong>Explanation:</strong> {{ showingStatus.explanation }}</p>
            </div>
        </div>
    </div>
  </div>

  
</template>

<script>
import {Form, Field, ErrorMessage} from 'vee-validate';
import * as yup from 'yup';
import ReportsService from '../../services/reports.service';
import Timestamp from '../../components/Timestamp.vue';
import ClientService from '../../services/client.service';
import CancellationService from '../../services/cancellation.service';
import {DateTime} from 'luxon';
import { Chart, registerables } from "chart.js";
import Multiselect from 'vue-multiselect';
import { ExportToCsv } from 'export-to-csv';
import * as XLSX from 'xlsx';
import claimsService from '../../services/claims.service';
import MessageCard from '../../components/MessageCard.vue';
import VueApexCharts from 'vue3-apexcharts';

Chart.register(...registerables);

export default {
  name: 'Reports',
  components: {
    Form,
    Field,
    ErrorMessage,
    Timestamp,
    Multiselect,
    MessageCard,
    VueApexCharts
  },
  props: {
    registration: String,
    site: Object,
    group: Number,
    type: Object,
    status: String,
    start_date: String,
    end_date: String,
    escalated_start_date: String,
    escalated_end_date: String
  },
  data(){
    const schema = yup.object().shape({
      registration: yup.string().min(3, 'Must be at least 3 characters!').max(12, 'Must be no more than 12 characters!').optional().nullable(),
      site: yup.array().optional().nullable(),
      group: yup.number().optional().nullable(),
      type: yup.array().optional().nullable(),
      status: yup.array().optional(),
      start_date: yup.date().nullable().optional(),
      end_date: yup.date().nullable().optional(),
      // escalated_start_date: yup.date().nulllable().optional(),
      // escalated_end_date: yup.date().nulllable().optional(),
      reference: yup.string().nullable().optional()
    });
    return {
      sites: [],
      siteGroups: [],
      schema: schema,
      claims: [],
      filters: {
        registration: null,
        site: [],
        start_date: null,
        end_date: null,
        escalated_start_date: null,
        escalated_end_date:null,
        status: [],
        type: [],
        reference: null,
        group: null
      },
      loading: false,
      isClaimsEmpty: false,
      isShowingReports: false,
      isShowingTotals: false,
      showTotalsButton: false,
      siteTotalsList: [],
      totalValue: 0,
      typeChart: {
        labels: [],
        datasets: [
          {
            data: [],
            backgroundColor: ['#FF7733', '#3B3561', '#51A3A3']
          }
        ],
        loading: true
      },
      siteChart: {
        labels: [],
        datasets: [
          {
            data: [],
            backgroundColor: ['#51A3A3', '#FF7733', '#3B3561']
          }
        ],
        loading: true
      },
      statusChart: {
        labels: [],
        datasets: [
          {
            data: [],
            backgroundColor: ['#51A3A3', '#FF7733', '#3B3561', '#D1D1D1']
          }
        ],
        loading: true
      },
      // value: [],
      statuses: [],
      cancelClaimModal: null,
      cancelReasonModal: null,
      cancelledClaim: {
        cancellation_reason: '' 
      },
      cancelClaimData: {
        claim: null,
        selected_reason: null,
        reason: null,
        send_cancellation_notice: false,
        processing: false
      },
      charts: {
        claimType: {
          chartOptions: {
            chart: {
              id: 'vuechart-claimType'
            },
            labels: [],
            dataLabels: {
              enabled: true
            },
            legend: {
              show: false
            }
          },
          series: [],
          show: false
        },
        site: {
          chartOptions: {
            chart: {
              id: 'vuechart-site'
            },
            labels: [],
            dataLabels: {
              enabled: true
            },
            legend: {
              show: false
            }
          },
          series: [],
          show: false
        },
        status: {
          chartOptions: {
            chart: {
              id: 'vuechart-site'
            },
            labels: [],
            dataLabels: {
              enabled: true
            },
            legend: {
              show: false
            }
          },
          series: [],
          show: false
        }
      },
      showCharts: true,
      sort_by: 'date_paid',
      value_search: null,
      requested_by_site: null,
      claimTypes: [
        {label: 'Drive Off', value: 'driveoff'},
        {label: 'No Means Of Payment', value: 'nmop'},
        {label: 'No Means Of Payment (Escalated)', value: 'escalated'},
        {label: 'Parking', value: 'parking'},
        {label: 'EV Parking', value: 'evparking'},
        {label: 'Customer Not at Fault', value: 'cnaf'},
        {label: 'Customer Not at Fault (Escalated)', value: 'cnafe'}
      ],
      statusExplanations: {
        'Paid': 'We have received payment in full',
        'Cancelled': 'The claim has been cancelled',
        'Being Processed': 'Our team is currently reviewing this claim',
        'At DVLA': 'We have sent a request off to the DVLA and are waiting for their response',
        'Sent to Keeper': 'We have sent a notice to the keeper and are waiting for payment',
        'Final Reminder': 'We have sent a final reminder and are waiting for payment',
        'DVLA Review': 'Our team are reviewing the DVLA response',
        'Awaiting Evidence': 'Our team have requested further evidence before they process the claim',
        'Keeper Appealed': 'The keeper has appealed the claim and our team is currently reviewing the appeal',
        'Appeal Rejected': 'Our team has rejected an appeal and an appeal rejection letter has been sent',
        'Debt Collection': 'We have passed this claim over to our debt collection agent',
        'Debt Collection Review': 'Our team are reviewing the claim before sending over to our debt collection agent',
        'KADOE Error': 'We have received an error from the KADOE system and are waiting for a response from the DVLA',
        'Paid on Site': 'Payment has been received on site and the claim is now closed',
        'Locked': 'Our team has paused processing on this claim whilst they investigate further. There can be a number of reasons we do this and if you have any questions please contact us',
        'Mail Queue': 'A notice has been issued and is waiting to be sent',
        'Letter Sent': 'A Customer Not at Fault letter has been sent and we are waiting for a payment',
        'Second Letter Sent': 'We have sent a final reminder and are waiting for payment',
      },
      showingStatus: {
        status: '',
        explanation: '',
        modal: null
      },
      statusFilter: null,
      siteFilter: null,
      typeFilter: null
    }
  },
  mounted() {
    this.cancelClaimModal = new bootstrap.Modal(document.getElementById('cancelClaimModal'));
    this.cancelReasonModal = new bootstrap.Modal(document.getElementById('cancelReasonModal'));
    this.showStatus.modal = new bootstrap.Modal(document.getElementById('statusExplanation'));
    this.init();

    // this.$root.$on('generate report', this.generate());
  },
  methods: {
    handleDataPointSelection(...args){
      console.log(`Selected ${this.charts.status.chartOptions.labels[args[2].dataPointIndex]}`);
      if(this.charts.status.chartOptions.labels[args[2].dataPointIndex] == this.statusFilter){
        this.statusFilter = null;
        return;
      }
      this.statusFilter = this.charts.status.chartOptions.labels[args[2].dataPointIndex];
    },
    handleDataPointSelectionSites(...args){
      if(this.charts.site.chartOptions.labels[args[2].dataPointIndex] == this.siteFilter){
        this.siteFilter = null;
        return;
      }
      this.siteFilter = this.charts.site.chartOptions.labels[args[2].dataPointIndex];
    },
    handleDataPointSelectionTypes(...args){
      if(this.charts.claimType.chartOptions.labels[args[2].dataPointIndex] == this.typeFilter){
        this.typeFilter = null;
        return;
      }
      this.typeFilter = this.charts.claimType.chartOptions.labels[args[2].dataPointIndex];
    },
    showStatus(claim){
      this.showingStatus.status = claim.status;
      this.showingStatus.explanation = claim.status_explanation;
      this.showStatus.modal.show();
    },
    async init(){
      await this.loadSites();
      await this.loadSiteGroups();
      await this.getStatuses();
      if(this.isValid(this.registration)) {
        this.filters.registration = this.registration;
      }

      if(this.isValid(this.site)) {
        let siteSearch = this.sites.filter((s) => {
          return parseInt(s.id) === parseInt(this.site);
        })[0];
        if(siteSearch){
          this.filters.site.push(siteSearch);
        }
      }

      if(this.isValid(this.group)){
        let groupSearch = this.siteGroups.filter((sg) => {
          return parseInt(sg.id) === parseInt(this.group);
        })[0];
        if(groupSearch){
          this.filters.group = groupSearch;
        }
      }
      
      if(this.isValid(this.type)) {
        let typeSearch = this.claimTypes.filter(t => {
          return this.type === t.value
        })[0];
        console.log(typeSearch);
        if(typeSearch) {
          this.filters.type.push(typeSearch);
        }
      }

      console.log("Status Prop: ", this.status);
      if(this.isValid(this.status)) {
        if(this.status === 'outstanding') {
          console.log('checking statuses');
          console.log(this.statuses.filter(status => (status !== 'Paid' && status !== 'Paid on Site' && status !== 'Cancelled')));
          this.filters.status = this.statuses.filter(status => (status !== 'Paid' && status !== 'Paid on Site' && status !== 'Cancelled'));
        }
        this.filters.status.push(this.status);
      }
      if(this.isValid(this.start_date)) {
        console.log("sdnfsodinf", this.start_date)
        this.filters.start_date = this.start_date;
      }
      if(this.isValid(this.end_date)) {
        this.filters.end_date = this.end_date;
      }
      if(this.isValid(this.escalated_start_date)) {
        this.filters.escalated_start_date = this.escalated_start_date;
      }
      if(this.isValid(this.escalated_end_date)) {
        this.filters.escalated_end_date = this.escalated_end_date;
      }
      if(this.registration || this.site || this.type || this.status || this.start_date || this.end_date || this.escalated_start_date || this.escalated_end_date) {
        this.generate(this.filters);
      }
    },
    generate(values) {
      this.loading = true;
      this.isClaimsEmpty = false;

      console.log("Ecalated Check", this.filters.escalated_start_date + this.filters.escalated_end_date);

      //vue-multiselect passes the whole object instead of just the value for the selected type
      let types = [];
      if(this.filters.type.length > 0) {
        this.filters.type.forEach(obj => {
          types.push(obj.value);
        });
      }

      let sites = [];
      if(this.filters.site.length > 0) {
        this.filters.site.forEach(obj => {
          sites.push(obj.id);
        });
      }

      let start_date = null;
      if((values.start_date || null) !== null){
        start_date = DateTime.fromFormat(values.start_date, 'yyyy-MM-dd').toFormat('dd/MM/yyyy');
      }
      let end_date = null;
      if((values.end_date || null) !== null){
        end_date = DateTime.fromFormat(values.end_date, 'yyyy-MM-dd').toFormat('dd/MM/yyyy');
      }

      let escalated_start_date = null;
      if((values.escalated_start_date || null) !== null){
        escalated_start_date = DateTime.fromFormat(values.escalated_start_date, 'yyyy-MM-dd').toFormat('dd/MM/yyyy');
      }
      let escalated_end_date = null;
      if((values.escalated_end_date || null) !== null){
        escalated_end_date = DateTime.fromFormat(values.escalated_end_date, 'yyyy-MM-dd').toFormat('dd/MM/yyyy');
      }

      ReportsService.getClaimReport(this.$store.state.auth.user.selectedClient, {
        registration: values.registration || null,
        site: sites || null,
        type: types || null,
        start_date: start_date || null,
        end_date: end_date,
        escalated_start: escalated_start_date || null,
        escalated_end: escalated_end_date || null,
        status: this.filters.status || null,
        reference: values.reference || null,
        group: this.filters.group?.id || null,
        value_search: values.value_filter || null,
        min_value: values.min_value || null,
        max_value: values.max_value || null,
        exact_value: values.value || null,
        requested_by_site: this.requested_by_site || null
      })
      .then(response => {
        //Filter according to date paid if only paid claims are retrieved 
        if(this.filters.status.length == 1 && this.filters.status[0] === 'Paid'){
          response.data.claims.sort((a,b) => {
            if(a.date_paid > b.date_paid){
                return -1;
            }
            if(a.date_paid < b.date_paid){
                return 1;
            }
            return 0
          });  
        } else {
          response.data.claims.sort((a,b) => {
            if(a.date_received > b.date_received){
                return -1;
            }
            if(a.date_paid < b.date_paid){
                return 1;
            }
            return 0
          }); 
        }
        response.data.claims.forEach((claim) => {
          if(claim.status === 'Pending'){
            claim.status = 'Being Processed';
          }
          let explanation = this.statusExplanations[claim.status];
          if(explanation) {
            claim.status_explanation = explanation;
          }else{
            claim.status_explanation = 'Could not find explanation. Please contact support for more information.';
          }

          claim.formattedType = {
            'cnaf': 'Customer Not at Fault',
            'cnafe': 'Drive Off',
            'nmop': 'No Means of Payment',
            'escalated': 'Drive Off',
            'driveoff': 'Drive Off',
            'parking': 'Parking',
            'evparking': 'EV Parking'
          }[claim.type];

        })
        this.claims = response.data.claims;
        if(this.claims.length < 1) {
          this.isClaimsEmpty = true;
        }
        this.loading = false;
        this.typeChartData();
        this.siteChartData();
        this.statusChartData();
      })
      .catch(error => {
        this.$error("Failed to generate report", error);
        this.loading = false;
      });

    },
    async loadSites(group = null) {
        return ClientService.getClientSites(this.$store.state.auth.user.selectedClient, false, group?.id || null)
        .then(response => {
            this.sites = response.data.sort((a,b) => {
              if(a.name < b.name){
                return -1;
              }
              if(a.name > b.name){
                return 1;
              }
              return 0;
            });
            this.sites.forEach((site) => {
              if(site.customer_reference){
                site.name = '(' + site.customer_reference + ') ' + site.name;
              }
            })
        })
        .catch(error => {
          this.$error(this.$t('sites.site_load_error'), error);
        })
    },
    async loadSiteGroups(){
      return ClientService.getClientSiteGroups(this.$store.state.auth.user.selectedClient)
        .then(response => {
            this.siteGroups = response.data.sort((a,b) => {
              if(a.name < b.name){
                return -1;
              }
              if(a.name > b.name){
                return 1;
              }
              return 0;
            });
        })
        .catch(error => {
          this.$error(this.$t('sites.site_load_error'), error);
        })
    },
    getSiteTotals() {
      this.loading = true;

      const siteTotals = [];
      const status = "Paid on Site";
      
      let totalValue = 0;

      for (const record of this.claims) {
        if (record.status === status) {
          let siteName = record.site.name;

          let siteTotal = siteTotals.find((site) => site.site == siteName);
          
          if (!siteTotal) {
            siteTotal = {
              status: status,
              site: siteName,
              totalValue: 0,
            };
            siteTotals.push(siteTotal);
          }
          
          siteTotal.totalValue += parseFloat(record.value);
          siteTotal.totalValue = parseFloat(siteTotal.totalValue.toFixed(2));

          totalValue += parseFloat(record.value);
          totalValue = parseFloat(totalValue.toFixed(2));
        }
      }

      this.siteTotalsList = siteTotals;
      this.totalValue = totalValue;

      console.log("site totals List", this.siteTotalsList);
      console.log("totals value", this.totalValue);

      this.isShowingTotals = true;
      this.isShowingReports = false;
      this.loading = false;
    },
    openClaim(claim) {
      let route = this.$router.resolve(`/claims/view?id=${claim.id}&client_id=${this.$store.state.auth.user.selectedClient}`)
      // this.$router.push(route.href); 
      window.open(route.href);
    },
    updateWeek(event) {
      event.preventDefault();
      this.filters.start_date = DateTime.local().startOf('week').toFormat('yyyy-MM-dd');
      this.filters.end_date = DateTime.local().endOf('week').toFormat('yyyy-MM-dd');
    },
    updateMonth(event) {
      event.preventDefault();
      this.filters.start_date = DateTime.local().startOf('month').toFormat('yyyy-MM-dd');
      this.filters.end_date = DateTime.local().endOf('month').toFormat('yyyy-MM-dd');
    },
    updateQuarter(event) {
      event.preventDefault();
      this.filters.start_date = DateTime.local().startOf('quarter').toFormat('yyyy-MM-dd');
      this.filters.end_date = DateTime.local().endOf('quarter').toFormat('yyyy-MM-dd');
    },
    updateYear(event) {
      event.preventDefault();
      this.filters.start_date = DateTime.local().startOf('year').toFormat('yyyy-MM-dd');
      this.filters.end_date = DateTime.local().endOf('year').toFormat('yyyy-MM-dd');
    },
    typeChartData() {
      this.charts.claimType.show = false;
      //Return if claims is empty 
      if(this.claims === null || this.claims === undefined ) return;

      //Make sure data array is empty 
      // this.typeChart.datasets[0].data = [];
      // this.typeChart.labels = [];
      // let temp = {};
      // this.claims.forEach(claim => {
      //   if(!temp[claim.type]) {
      //     temp[claim.type] = 1;
      //   } else {
      //     temp[claim.type] += 1;
      //   }
      // });

      // for(let key in temp) {
      //   this.typeChart.labels.push(key);
      //   this.typeChart.datasets[0].data.push(temp[key]);
      // }

      this.charts.claimType.series = [];
      this.charts.claimType.chartOptions.labels = [];
      let temp = {};
      this.claims.forEach(claim => {
        if(!temp[claim.type]) {
          temp[claim.type] = 1;
        } else {
          temp[claim.type] += 1;
        }
      });

      for(let key in temp) {
        this.charts.claimType.chartOptions.labels.push(key);
        this.charts.claimType.series.push(temp[key]);
      }
 
      if(Object.keys(temp).length > 1) {
        this.charts.claimType.show = true;
      }

    },
    siteChartData() {
      this.charts.site.show = false;
      //Return if claims is empty 
      if(this.claims === null || this.claims === undefined ) return;

      // this.siteChart.datasets[0].data = [];
      // this.siteChart.labels = [];
      this.charts.site.series = [];
      this.charts.site.chartOptions.labels = [];
      let temp = {};
      this.claims.forEach(claim => {
        if(!temp[claim.site.name]) {
          temp[claim.site.name] = 1;
        } else {
          temp[claim.site.name] += 1;
        }
      });

      for(let key in temp) {
        // this.siteChart.labels.push(key);
        // this.siteChart.datasets[0].data.push(temp[key]);
        this.charts.site.chartOptions.labels.push(key);
        this.charts.site.series.push(temp[key]);
      }

      if(Object.keys(temp).length > 1) {
        this.charts.site.show = true;
      }
    },
    statusChartData() {
      this.charts.status.show = false;
       //Return if claims is empty 
      if(this.claims === null || this.claims === undefined ) return;

      // this.statusChart.datasets[0].data = [];
      // this.statusChart.labels = [];
      this.charts.status.series = [];
      this.charts.status.chartOptions.labels = [];
      let temp = {};
      this.claims.forEach(claim => {
        if(!temp[claim.status]) {
          temp[claim.status] = 1;
        } else {
          temp[claim.status] += 1;
        }
      });

      for(let key in temp) {
        console.log(key, temp[key], 'Status chart');
        // this.statusChart.labels.push(key);
        // this.statusChart.datasets[0].data.push(temp[key]);
        this.charts.status.chartOptions.labels.push(key);
        this.charts.status.series.push(temp[key]);
      }

      this.charts.status.chartOptions = {
        ...this.charts.status.chartOptions,
      };

      if(Object.keys(temp).length > 1) {
        this.charts.status.show = true;
      }
    },
    openModal(claim) {
      this.cancelClaimData.claim = claim;
      this.cancelClaimModal.show();
      // $('#cancelClaimModal').modal('toggle');
    },
    openCancelReasonModal(claim) {
      this.cancelledClaim = claim;
      this.cancelReasonModal.show();
    },
    processCancellation() {
      this.cancelClaimData.processing = true;
      CancellationService.requestCancellation({
        reason: this.cancelClaimData.reason,
        selected_reason: this.cancelClaimData.selected_reason,
        send_notice: this.cancelClaimData.send_cancellation_notice,
        claim_id: this.cancelClaimData.claim.id,
        client_id: this.$store.state.auth.user.selectedClient,
        site_id: this.cancelClaimData.claim.site.id
      })
      .then(response => {
        if(response.status !== 200) {
          throw new Error('Unable to submit cancellation request');
        }
        this.$success(this.$t('reports.success_msg'));
        this.cancelClaimModal.hide();
        this.clearCancellation();
      })
      .catch(error => {
        this.$error(this.$t('reports.error_msg'), error);
        this.cancelClaimData.processing = false;
        this.cancelClaimModal.hide();
      })
    },
    clearCancellation() {
      this.cancelClaimData = {
        claim: null,
        reason: null,
        notes: null,
        send_cancellation_notice: false,
        processing: false
      }
    },
    exportCSV() {
      var data = [];
      for(let claim of this.claims) {
        let incidentDate, incidentTime;
        if(claim.incident_timestamp){
          incidentDate = DateTime.fromSeconds(claim.incident_timestamp).toFormat('dd/MM/yyyy');
          incidentTime = DateTime.fromSeconds(claim.incident_timestamp).toFormat('HH:mm:ss');
          if(incidentDate === 'Invalid DateTime') {
            incidentDate = "";
          }
          if(incidentTime === 'Invalid DateTime') {
            incidentTime = "";
          }
        }
        if(claim.status == 'Paid on Site'){
          claim.date_paid = claim.date_cancelled;
        }
        if(this.$store.state.auth.user.selectedClient == 72){
          data.push({
            "Plate": claim.registration,
            "Reference": claim.reference,
            "Site Name": claim.site.name,
            "Ship To Number": claim.site.customer_reference,
            "Legal Trading Entity": claim.site_group?.name || "",
            "Sold To Number": claim.site_group?.customer_reference || "",
            "Type": this.claimTypes.find(type => type.value === claim.type).label,
            "Status": claim.status,
            "Date Received": DateTime.fromSeconds(claim.date_received).toFormat('dd/MM/yyyy'),
            "Date Paid": claim.date_paid ? DateTime.fromSeconds(claim.date_paid).toFormat('dd/MM/yyyy') : "",
            "Date Cancelled": claim.date_cancelled ? DateTime.fromSeconds(claim.date_cancelled).toFormat('dd/MM/yyyy') : "",
            "Incident Date": incidentDate || "",
            "Incident Time": incidentTime || "",
            "Cancellation reason": claim.cancellation_reason || "",
            "Cancellation Category": claim.cancellation_category || "",
            "Value": claim.value,
            "Pump": claim.pump || "",
            "Second Pump": claim.pump_second || "",
          })
        }else{
          data.push({
            "Plate": claim.registration,
            "Reference": claim.reference,
            "Site Name": claim.site.name,
            "Type": this.claimTypes.find(type => type.value === claim.type).label,
            "Status": claim.status,
            "Date Received": DateTime.fromSeconds(claim.date_received).toFormat('dd/MM/yyyy'),
            "Date Paid": claim.date_paid ? DateTime.fromSeconds(claim.date_paid).toFormat('dd/MM/yyyy') : "",
            "Date Cancelled": claim.date_cancelled ? DateTime.fromSeconds(claim.date_cancelled).toFormat('dd/MM/yyyy') : "",
            "Incident Date": incidentDate || "",
            "Incident Time": incidentTime || "",
            "Cancellation reason": claim.cancellation_reason || "",
            "Cancellation Category": claim.cancellation_category || "",
            "Value": claim.value,
            "Pump": claim.pump || "",
            "Second Pump": claim.pump_second || "",
          })
        }
      }

      const options = {
        fieldSeparator: ',',
        quoteStrings: '"',
        decimalSeparator: '.',
        showLabels: true,
        showTitle: true,
        title: `VARS Claim Report - ${DateTime.local().toFormat('dd/MM/yyyy')}`,
        useTextFile: false,
        useBom: true,
        useKeysAsHeaders: true,
      }

      const csvExporter = new ExportToCsv(options);
      csvExporter.generateCsv(data);
    },
    exportExcel() {



      var data = [];
      for(let claim of this.claims) {
        let incidentDate, incidentTime;
        if(claim.incident_timestamp){
          incidentDate = DateTime.fromSeconds(claim.incident_timestamp).toFormat('dd/MM/yyyy');
          incidentTime = DateTime.fromSeconds(claim.incident_timestamp).toFormat('HH:mm:ss');
          if(incidentDate === 'Invalid DateTime') {
            incidentDate = "";
          }
          if(incidentTime === 'Invalid DateTime') {
            incidentTime = "";
          }
        }
        let claimType = this.claimTypes.find(type => type.value === claim.type)?.label || claim.type;
        if(claim.type == 'escalated'){
          claimType = 'No Means of Payment (Escalated)';
        }
        if(claim.status == 'Paid on Site'){
          claim.date_paid = claim.date_cancelled;
        }
        if(this.$store.state.auth.user.selectedClient == 72){
          data.push({
            "Plate": claim.registration,
            "Reference": claim.reference,
            "Site Name": claim.site.name,
            "Ship To Number": claim.site.customer_reference,
            "Legal Trading Entity": claim.site_group?.name || "",
            "Sold To Number": claim.site_group?.customer_reference || "",
            "Type": claimType,
            "Status": claim.status,
            "Date Received": DateTime.fromSeconds(claim.date_received).toFormat('dd/MM/yyyy'),
            "Date Paid": claim.date_paid ? DateTime.fromSeconds(claim.date_paid).toFormat('dd/MM/yyyy') : "",
            "Date Cancelled": claim.date_cancelled ? DateTime.fromSeconds(claim.date_cancelled).toFormat('dd/MM/yyyy') : "",
            "Incident Date": incidentDate || "",
            "Incident Time": incidentTime || "",
            "Cancellation Reason": claim.cancellation_reason || "",
            "Cancellation Category": claim.cancellation_category || "",
            "Value": parseFloat(parseFloat(claim.value).toFixed(2)),
            "Pump": claim.pump || "",
            "Second Pump": claim.pump_second || "",
          })
        }else{
          data.push({
            "Plate": claim.registration,
            "Reference": claim.reference,
            "Site Name": claim.site.name,
            "Type": claimType,
            "Status": claim.status,
            "Date Received": DateTime.fromSeconds(claim.date_received).toFormat('dd/MM/yyyy'),
            "Date Paid": claim.date_paid ? DateTime.fromSeconds(claim.date_paid).toFormat('dd/MM/yyyy') : "",
            "Date Cancelled": claim.date_cancelled ? DateTime.fromSeconds(claim.date_cancelled).toFormat('dd/MM/yyyy') : "",
            "Incident Date": incidentDate || "",
            "Incident Time": incidentTime || "",
            "Cancellation Reason": claim.cancellation_reason || "",
            "Cancellation Category": claim.cancellation_category || "",
            "Value": parseFloat(parseFloat(claim.value).toFixed(2)),
            "Pump": claim.pump || "",
            "Second Pump": claim.pump_second || "",
          })
        }
        
      }

      // Aquire Data
      // let table = document.getElementById("reporttable");

      // Drop action column 
      // let row = table.rows;
      // for (var i = 0; i < row[0].cells.length; i++) {
      //   var str = row[0].cells[i].innerHTML;
      //   if (str.search("Action") != -1) { 
      //       for (var j = 0; j < row.length; j++) {
      //         row[j].deleteCell(i);
      //       }
      //   }
      // }

      //Extract Data (create a workbook object from the table)
      // let workbook = XLSX.utils.table_to_book(table);
      let workbook = XLSX.utils.book_new();
      let worksheet = XLSX.utils.json_to_sheet(data);
      XLSX.utils.book_append_sheet(workbook, worksheet, "Claims Report");
      
      // Process Data (add a new row)
      // let ws = worksheet.Sheets["Sheet1"];
      // XLSX.utils.sheet_add_aoa(ws, [["Created " + new Date().toISOString()]], {origin:-1});

      // Package and Release Data (`writeFile` tries to write and save an XLSB file)
      XLSX.writeFile(workbook, "ClaimReport.xlsx");
    },
    async getStatuses() {
      return claimsService.getStatuses(this.$store.state.auth.user.selectedClient)
      .then(response => {
        this.statuses = response.data.statuses;
      })
      .catch(error => {
        console.log('Failed to load statues.',error);
      })
    },
    clearFilter() {
      this.filters = {
        registration: null,
        site: [],
        start_date: null,
        end_date: null,
        escalated_start_date: null,
        escalated_end_date:null,
        status: [],
        type: [],
        reference: null
      }
    },
    toggleShowCharts() {
      this.showCharts = !this.showCharts;
    },
    isValid(value) {
      if(value === null) return false;
      if(value === undefined) return false;

      if(typeof value === 'string') {
        if(value.length < 1) return false;
      }

      return true;
    },
    sortDateField() {
      if(this.sort_by === 'date_received') {
        this.claims.sort((a,b) => {
          if(a.date_received > b.date_received){
              return -1;
          }
          if(a.date_paid < b.date_paid){
              return 1;
          }
          return 0
        }); 
      } else {
        this.claims.sort((a,b) => {
          if(a.date_paid > b.date_paid){
              return -1;
          }
          if(a.date_paid < b.date_paid){
              return 1;
          }
          return 0
        });
      }
    }
  },
  computed: {
    totalPaid: function(){
      let total = 0.00;
      this.filteredClaims.forEach((claim) => {
        if(claim.status === 'Paid' || claim.status === 'Paid on Site'){
          total += (parseFloat(claim.value) + (parseFloat(claim.value_second || 0)));
        }
      });
      return parseFloat(total).toLocaleString();
    },
    totalCancelled: function(){
      let total = 0.00;
      this.filteredClaims.forEach((claim) => {
        if(claim.status === 'Cancelled'){
          total += (parseFloat(claim.value) + (parseFloat(claim.value_second || 0)));
        }
      });
      return parseFloat(total).toLocaleString();
    },
    totalValue: function(){
      let total = 0.00;
      this.filteredClaims.forEach((claim) => {
        total += (parseFloat(claim.value || 0) + (parseFloat(claim.value_second || 0)));
      });
      return parseFloat(total).toLocaleString();
    },
    dateFilter: function() {
      // if(this.filters.status.length == 1 && this.filters.status[0] === 'Paid'){
      //   if(this.sort_by === 'date_paid') return 'Date Paid';
      //   if(this.sort_by === 'date_received') return 'Date Received';
      // } 
      return 'Date Received';
    },
    siteTotalValue() {
      return this.duplicatedPaidonSite.reduce((total, record) => total + record.value, 0);
    },
    filteredClaims: function(){
      let filteredClaims = this.claims;
      if(this.statusFilter !== null) {
        filteredClaims = filteredClaims.filter(claim => claim.status === this.statusFilter);
      }
      if(this.siteFilter !== null) {
        filteredClaims = filteredClaims.filter(claim => claim.site.name === this.siteFilter);
      }
      if(this.typeFilter !== null) {
        filteredClaims = filteredClaims.filter(claim => claim.type === this.typeFilter);
      }
      return filteredClaims;
    }
  }
}
</script>

<style scoped>
/* src="../../../node_modules/vue-multiselect/dist/dist/vue-multiselect.css" */
  td > span {
    display: block;
  }

  .btn {
    padding: 10px 20px;
  }

  .table-heading {
    padding-top: 0;
    margin-bottom: 20px;
  }

  .reports-container { 
    height: fit-content;
    margin-top: 25px;
  }

  .form-control, .form-select {
    /* border-color: #393946; */
    /* background-color: var(--bg-color-tertiary); */
  }

  .form-check-label {
    color: #CDC4D1;
    font-size: 16px;
    font-weight: 100;
  }

  .form-check-input:checked + .form-check-label{
    color: white;
  }

  .form-check-input {
    border-radius: 0;
    margin-right: 15px;
    width: 20px;
    height: 20px;
  }

  *:not(.form-switch) > .form-check-input:not(:checked) {
    background: #CDC4D1;
    border: 1px solid #CDC4D1;
  }

  .btn-secondary:focus {
    background-color: #598EF7;
    border-color: #598EF7;
  }

  .no-report-container {
    width: 100%;
    text-align: center;
    font-style: italic;
    color: #FF9500;
    font-size: 16px;
  }

  .width-fit-content {
    margin: auto;
    margin-top: 25px;
  }

  .temp {
    width: 30px;
    height: 30px;
    border-radius: 50%;
  }

  .temp:hover {
    cursor: pointer;
  }

  .modal-sub-title {
    font-weight: 100;
    /* color: #CDC4D1; */
  }

  .form-checkbox {
    padding: 5px;
    margin-right: 10px;
  }

  .checkbox-container {
    font-weight: 300;
    margin-top: 1rem;
  }

  .checkbox-container input {
    width: 12px;
    height: 12px;
  }

  .display-none {
    display: none;
  }

  #statusFilter input {
    border: none;
  }

  @media (min-width: 1440px) {
  .col-12 {
      width: 57%;
  }
  }

</style>
import axios from "axios";
import authHeader from "./auth-header";

const API_URL = process.env.VUE_APP_API_URL + "cancellation/";

class CancellationService {
  requestCancellation(claimData) {
    return axios.put(
      `https://api.varsanpr.com/api/claims/cancellation`,
      claimData,
      { headers: authHeader() }
    );
  }
}

export default new CancellationService();

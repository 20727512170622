import axios from "axios";
import authHeader from "./auth-header";

const API_URL = process.env.VUE_APP_API_URL;

class ReportService {
  getClaimReport(client, filters = {}) {
    let url = `https://api.varsanpr.com/api/reports/claims`;

    let data = {
      sort_by: "any",
      client_id: client,
    };

    if (filters.registration !== undefined && filters.registration !== null) {
      data.plate = filters.registration;
    }
    if (
      filters.site !== undefined &&
      filters.site !== null &&
      filters.site.length > 0
    ) {
      data.sites = filters.site;
    }
    if (
      filters.type !== undefined &&
      filters.type !== null &&
      filters.type.length > 0
    ) {
      data.types = filters.type;
    }
    console.log("Status Filter: ", filters.status);
    if (
      filters.status !== undefined &&
      filters.status !== null &&
      filters.status.length > 0
    ) {
      data.statuses = filters.status;
    }
    if (filters.start_date !== undefined && filters.start_date !== null) {
      data.start = filters.start_date;
    }
    if (filters.end_date !== undefined && filters.end_date !== null) {
      data.end = filters.end_date;
    }
    if (
      filters.escalated_start !== undefined &&
      filters.escalated_start !== null
    ) {
      data.escalated_start = filters.escalated_start;
    }
    if (filters.escalated_end !== undefined && filters.escalated_end !== null) {
      data.escalated_end = filters.escalated_end;
    }
    if (filters.reference !== undefined && filters.reference !== null) {
      data.reference = filters.reference;
    }
    if (filters.group !== undefined && filters.group !== null) {
      data.group = filters.group;
    }

    if (filters.value_search !== undefined && filters.value_search !== null) {
      data.value_search = filters.value_search;
    }

    if (
      filters.value_search == "range" &&
      filters.min_value !== undefined &&
      filters.min_value !== null
    ) {
      data.min_value = parseFloat(filters.min_value);
    }

    if (
      filters.value_search == "range" &&
      filters.max_value !== undefined &&
      filters.max_value !== null
    ) {
      data.max_value = parseFloat(filters.max_value);
    }

    if (
      filters.value_search == "exact" &&
      filters.exact_value !== undefined &&
      filters.exact_value !== null
    ) {
      data.exact_value = parseFloat(filters.exact_value);
    }

    if (filters.requested_by_site && filters.status.includes("Cancelled")) {
      data.requested_by_site = true;
    }

    console.log("Filters: ", filters);

    return axios.post(url, data, { headers: authHeader() });
  }
}

export default new ReportService();
